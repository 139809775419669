import { ReactNode } from 'react'
import Link from 'next/link'
import {
  confirmSignIn,
  fetchUserAttributes,
  sendUserAttributeVerificationCode,
} from 'aws-amplify/auth'

interface ErrorsType {
  invalidCodeError?: boolean,
  expiredCodeError?: boolean,
  codeSubmissionTechnicalError?: boolean,
  limitExceededError?: boolean,
}

const generalError = (
  <div>
    We are unable to submit your verification code due to a technical issue. Please try again.
    If this problem continues, please{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const getCodeErrorMessages = (errors: ErrorsType) => {
  const messages = []
  if (errors.invalidCodeError) {
    messages.push('Please enter a valid verification code.')
  }
  if (errors.limitExceededError) {
    messages.push('You have submitted too many verification codes. Please wait a few seconds before submitting another code.')
  }
  return messages
}

const getGeneralErrorMessages = (errors: ErrorsType) => {
  const messages = []
  if (errors.codeSubmissionTechnicalError) {
    messages.push(
      generalError,
    )
  }
  return messages
}

const submitCode = async (
  mfaCode: string,
  {
    refreshUser,
    onEmailUnconfirmed,
    onPhoneNumberUnverified,
    onEmailConfirmed,
  }:
    {refreshUser: (() => void)|null,
    onEmailUnconfirmed: () => Promise<void>,
    onPhoneNumberUnverified?: () => Promise<void> | undefined,
    onEmailConfirmed: () => Promise<void>},
) => {
  try {
    const result = await confirmSignIn({ challengeResponse: mfaCode })
    if (result?.isSignedIn) {
      if (refreshUser) {
        await refreshUser()
      }

      const userAttributes = await fetchUserAttributes()

      if (userAttributes.email_verified === 'false') {
        sendUserAttributeVerificationCode({ userAttributeKey: 'email' })
        await onEmailUnconfirmed()
      } else if (onPhoneNumberUnverified && userAttributes.phone_number_verified === 'false') {
        sendUserAttributeVerificationCode({ userAttributeKey: 'phone_number' })
        await onPhoneNumberUnverified()
      } else {
        await onEmailConfirmed()
      }
    }
  } catch (error) {
    if (!(error instanceof Error)) {
      return { codeSubmissionTechnicalError: true }
    }
    switch (error.name) {
      case 'CodeMismatchException':
        return { invalidCodeError: true }
      case 'NotAuthorizedException':
        return { limitExceededError: true }
      default:
        return { codeSubmissionTechnicalError: true }
    }
  }
  return {}
}

const submitMfaCode = ({
  refreshUser,
  onEmailUnconfirmed,
  onPhoneNumberUnverified,
  onEmailConfirmed,
}:
  {refreshUser: (() => void)|null,
  onEmailUnconfirmed: () => Promise<void>,
  onPhoneNumberUnverified?: () => Promise<void> | undefined,
  onEmailConfirmed: () => Promise<void>},
) => async (
  mfaCode: string,
) => {
  const additionalErrors = await submitCode(
    mfaCode,
    {
      refreshUser,
      onEmailUnconfirmed,
      onPhoneNumberUnverified,
      onEmailConfirmed,
    },
  )
  const elements: ReactNode[] = []
  elements.push(...getCodeErrorMessages(additionalErrors))
  elements.push(...getGeneralErrorMessages(additionalErrors))
  return elements
}

export default submitMfaCode
