import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useEffect } from 'react'

import { Accordion } from '@sh24/ui-components'
import { PrepLastKidneyFunctionResult } from '@sh24/admin-api-js'
import { useAppContext } from '../../../contexts/app-context'
import fetchResultCards from '../../../services/contentful/fetch-result-cards'

import { formatDate } from '../../../utils/helpers'
import ResultCardWithContext from '../../ResultCards/result-card-with-context'
import useTranslations from '../../../utils/use-translations'
import notifyError from '../../../services/exception-notifier/exception-notifier'

const AccordionContent = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
  `}
`

const nextTestsDueText = (date: Date) => {
  const translations = useTranslations()
  const { locale } = useRouter()

  const nextTestsDue = translations['prepDashboard.nextTestsDue']

  return `${nextTestsDue} ${formatDate({ dateString: date, locale })}`
}

const LastKidneyFunctionResult = ({
  prepSubscriptionState,
  lastKidneyFunctionResult,
  title,
}: {
  prepSubscriptionState: string,
  lastKidneyFunctionResult: PrepLastKidneyFunctionResult | null,
  title: string
}) => {
  const translations = useTranslations()

  const resultWithContext = {
    sh24Uid: lastKidneyFunctionResult?.sh24Uid,
    infection: 'kidneyFunction',
    sampleType: 'blood',
    outcome: lastKidneyFunctionResult?.result.outcome,
    numericalResult: lastKidneyFunctionResult?.result.numericalResult,
    linkedDerivedResults: lastKidneyFunctionResult?.result.linkedDerivedResults,
    updatedAt: lastKidneyFunctionResult?.result.createdAt.getTime(),
  }

  const { setAppContext, appContext } = useAppContext()

  useEffect(() => {
    const setResultCard = async () => {
      try {
        setAppContext(
          {
            ...appContext,
            resultCards: {
              ...appContext.resultCards,
              ...(await fetchResultCards({ preview: appContext.preview, types: ['kidneyFunction'] })),
            },
          },
        )
      } catch (err) {
        notifyError(err)
      }
    }

    setResultCard()
  }, [])

  if (!lastKidneyFunctionResult) {
    return (
      <Accordion
        variant="alternate"
        title={title}
        content={(
          <AccordionContent>
            {translations['prepDashboard.noResults']}
          </AccordionContent>
        )}
        closeText={translations['prepDashboard.close']}
      />
    )
  }

  return (
    <Accordion
      variant="alternate"
      title={title}
      previewContent={prepSubscriptionState === 'active' && lastKidneyFunctionResult.nextDue && nextTestsDueText(lastKidneyFunctionResult.nextDue)}
      content={(
        <AccordionContent>
          <ResultCardWithContext
            value={resultWithContext}
            resultCardsLoaded
            openByDefault={false}
            collapsible={false}
          />
        </AccordionContent>
      )}
      closeText={translations['prepDashboard.close']}
      alwaysShowPreviewContent
    />
  )
}

export default LastKidneyFunctionResult
