import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Content } from '@sh24/ui-components'
import LoginForm from '../../../Forms/SignIn/LoginForm/login-form'

const LoginModule = () => {
  const {
    push, query, replace,
  } = useRouter()

  const setRedirectUrl = async () => {
    const redirectUrl = query.redirect
    if (redirectUrl && typeof redirectUrl === 'string') {
      sessionStorage.setItem('redirectUrl', redirectUrl)
      await replace('/account/login')
    }
  }

  useEffect(() => {
    setRedirectUrl()
  }, [query])

  const onVerifiedEmailWithSmsMfa = async () => {
    await push('/account/verify-phone-number')
  }

  const onVerifiedEmailWithTotpMfa = async () => {
    await push('/account/verify-totp')
  }

  const onUnverifiedEmail = async () => {
    await push('/account/sign-up/verify-email')
  }

  const onMfaNotSetUp = async () => {
    await push('/account/sign-up/phone-number')
  }

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
        [
          <LoginForm
            key="form"
            onVerifiedEmailWithSmsMfa={onVerifiedEmailWithSmsMfa}
            onVerifiedEmailWithTotpMfa={onVerifiedEmailWithTotpMfa}
            onUnverifiedEmail={onUnverifiedEmail}
            onMfaNotSetUp={onMfaNotSetUp}
          />,
        ]
      }
    />
  )
}

export default LoginModule
