import {
  getCurrentStep,
  getActiveItems,
  getInactiveItems,
} from '../helpers'

/* eslint-disable-next-line import/prefer-default-export */
export const clearRedundantAnswers = (context) => {
  const step = getCurrentStep(context)
  const inactiveQuestionKeys = getInactiveItems(step?.questions, context).map((q) => q.key)
  const activeQuestionKeys = getActiveItems(step?.questions, context).map((q) => q.key)

  const staleAnswered = inactiveQuestionKeys.filter((key) => (
    !!context[key] && !activeQuestionKeys.includes(key)
  ))

  const nullifiedAnswers = staleAnswered?.reduce((prev, key) => ({ ...prev, [key]: null }), {})

  return nullifiedAnswers
}
