import { assign } from 'xstate'
import {
  satisfiesConditions,
  getValidQueryParams,
} from '../helpers'
import { calculateBasketTotals } from '../calculators'
import * as ASSIGNERS from './assigners'

export const CURRENT_JOURNEY_KEY = 'currentJourney'

export const updateContext = assign((_, event) => ({
  [event.key]: event.value,
}))

export const calculateTotals = assign({
  totals: calculateBasketTotals,
})

export const setErrors = assign({
  errors: ((_, event) => event?.data.toJSON()),
})

export const clearErrors = assign({
  errors: null,
})

export const setCurrentToNext = assign((context) => ({
  currentStep: context.currentStep + 1,
}))

export const setCurrentToPrevious = assign((context) => ({
  currentStep: context.currentStep - 1,
}))

export const getSession = ({ sessionKey }) => (
  JSON.parse(window.sessionStorage.getItem(sessionKey))
)

export const updateSession = (context) => {
  window.sessionStorage.setItem(context.sessionKey, JSON.stringify({ context }))
}

export const clearSession = ({ sessionKey }) => (
  window.sessionStorage.removeItem(sessionKey)
)

export const setAsCurrentJourney = ({ sessionKey }) => {
  window.sessionStorage.setItem(CURRENT_JOURNEY_KEY, sessionKey)
}

export const hydrateContext = assign((context) => {
  const sessionState = JSON.parse(window.sessionStorage.getItem(context.sessionKey))
  return {
    ready: true,
    ...(sessionState?.context || context),
  }
})

export const processServiceResponse = assign((_, event) => ({
  ...event?.data?.context,
}))

export const updateKickouts = assign((context) => {
  const { kickouts } = context.journey
  const kickout = kickouts?.find((k) => satisfiesConditions(context, k.conditions))
  const kickoutReason = kickout?.id || null

  return {
    kickoutReason,
  }
})

export const clearRedundantAnswers = assign(ASSIGNERS.clearRedundantAnswers)

export const saveQueryParams = assign((_, event) => {
  const validQuery = getValidQueryParams(event.query)
  return validQuery
})
