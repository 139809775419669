import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import VerifyEmailForm from '../../../Forms/SignUp/VerifyEmailForm/verify-email-form'

const SignUpVerifyEmailModule = () => {
  const { push } = useRouter()

  const onPhoneNumberUnverified = async () => {
    push('/account/sign-up/phone-number')
  }

  const onPhoneNumberVerified = async () => {
    const redirectUrl = await sessionStorage.getItem('redirectUrl')
    sessionStorage.removeItem('redirectUrl')
    await push(redirectUrl || '/account/home')
  }

  const onLoginRequired = async () => {
    sessionStorage.setItem('showLoginRequiredMessage', 'true')
    push('/account/login')
  }

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <VerifyEmailForm
          key="form"
          onPhoneNumberUnverified={onPhoneNumberUnverified}
          onPhoneNumberVerified={onPhoneNumberVerified}
          onLoginRequired={onLoginRequired}
        />,
      ]
    }
    />
  )
}

export default SignUpVerifyEmailModule
