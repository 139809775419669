import { useContext } from 'react'
import styled from 'styled-components'
import AccountContext from '../../../../contexts/account-context'
import ensureOrdersLoaded from '../../../../utils/ensure-orders-loaded'
import useTranslations from '../../../../utils/use-translations'
import ResultsHistoryDisplay from '../../../ResultsHistoryDisplay/results-history-display'

const Centered = styled.div`
  text-align: center;
`

const ResultsHistoryModule = () => {
  const { ordersContext } = useContext(AccountContext)
  const { orders, errors } = ordersContext
  ensureOrdersLoaded()

  const translations = useTranslations()

  if (errors?.errors) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your results. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
      </>
    )
  }

  if (!orders) {
    return (
      <div className="row row-centered">
        <div className="col col-12 col-md-6">
          <p>{translations['orderCard.loadingResults']}</p>
        </div>
      </div>
    )
  }

  const ordersWithResultState = orders.orders?.filter((order) => order.resultsState)

  if (ordersWithResultState.length === 0) {
    return (
      <Centered>
        <h2> No orders found</h2>
        <p>
          Placed your order in the last 24 hours? We&apos;re still processing your
          order, so don&apos;t worry that it&apos;s not appearing in your account
          yet.
        </p>
        <p>
          If you checked out as a guest when placing your order, then your order
          will not be listed in your account. You&apos;ll get your results by text
          message. We cannot change how you get your results.
        </p>
        <p>
          <b>If you haven&apos;t placed an order</b>, please{' '}
          <a href="/orders/new">order a STI test kit</a>. If
          you&apos;d like to view your results in your account, make sure you log in
          to your account when prompted.
        </p>
        <p>
          If you have any questions about your orders, please read{' '}
          <a href="/faqs">our FAQs</a> or{' '}
          <a href="/contact-us">contact us</a>.
        </p>
      </Centered>
    )
  }

  return (
    <div className="row row-centered">
      <div className="col col-12 col-md-6 col-lg-4">
        <ResultsHistoryDisplay
          orders={ordersWithResultState}
        />
      </div>
    </div>
  )
}

export default ResultsHistoryModule
