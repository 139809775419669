import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import PhoneNumberForm from '../../../Forms/SignUp/PhoneNumberForm/phone-number-form'

const SignUpPhoneNumberModule = () => {
  const { push } = useRouter()

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <PhoneNumberForm
          key="form"
          onUnverifiedPhoneNumber={() => push('/account/sign-up/verify-phone-number')}
        />,
      ]
    }
    />
  )
}

export default SignUpPhoneNumberModule
