import type { SiteConfig } from '@sh24/contentful-service'
import { useAppContext } from '../contexts/app-context'

const getFlagValue = (siteConfig: SiteConfig | undefined, flagName: string) => {
  const config = siteConfig ?? useAppContext().appContext
  return Boolean(config?.featureFlags?.[flagName])
}

export const usePartnerNotificationOfferCard = (siteConfig?: SiteConfig) => getFlagValue(siteConfig, 'partnerNotificationOfferCard')

export default getFlagValue
