import { BreakpointHelpers } from '@sh24/ui-components'
import styled from 'styled-components'
import { useState } from 'react'
import { ConsultationStates } from '@sh24/admin-api-js'
import StiTestOrderStatus from '../../OrderStatusCards/StiTestOrderStatus/sti-test-order-status'
import useTranslations from '../../../utils/use-translations'

type OrderStatusData = {
  sh24Uid: string
  orderState: string
  updatedAt: Date
  consultation?: {
    schedulingAppointment?: Date
    schedulingUrl: string
    state: ConsultationStates
    appointmentStart?: Date
    appointmentEnd?: Date
    updatedAt: Date
  }
}

type StatusContainerTypes = {
  lastStiTestOrder: OrderStatusData | null,
}

const { mobileOnly, tabletUp } = BreakpointHelpers

const Centered = styled.div`
  text-align: center;
`

const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
    display: flex;
    height: auto;

    ${mobileOnly(`
      flex-direction: column;
      gap: ${theme?.spacing?.sm};
    `)}

    ${tabletUp(`
      flex-direction: row;
      justify-content: center;
      gap: ${theme?.spacing?.md};

      > div {
        width: 40%;
      }
    `)}
  `}
`
const OrderStatusesContainer = ({
  lastStiTestOrder,
}: StatusContainerTypes) => {
  const [statusCardVisible, setStatusCardVisible] = useState(true)

  const translations = useTranslations()
  const stiTestOrderStatusCard = statusCardVisible
    && (<StiTestOrderStatus stiTestOrderData={lastStiTestOrder} setStatusCardVisible={setStatusCardVisible} />)

  if (!statusCardVisible) {
    return (
      <Centered>
        <p>{translations['accountOrderUpdates.noOrderUpdates']}</p>
      </Centered>
    )
  }

  return (
    <Container>
      {stiTestOrderStatusCard}
    </Container>
  )
}

export default OrderStatusesContainer
