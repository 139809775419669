import React, { useState, ReactNode } from 'react'
import { Button, ErrorMessages, TextField } from '@sh24/ui-components'
import ResendVerificationCodePanel from '../ResendVerificationCode/resend-verification-code'

const VerifyPhoneNumberForm = (
  {
    requestVerificationCode,
    submitVerificationCode,
    phoneNumber = undefined,
    codeReRequestTime = 60,
  }: {
    requestVerificationCode: () => Promise<ReactNode[]>,
    submitVerificationCode: (smsCode: string) => Promise<ReactNode[]>,
    phoneNumber?: string,
    codeReRequestTime?: number,
  },
) => {
  const [smsCode, setSmsCode] = useState('')
  const [errors, setErrors] = useState<ReactNode[]>([])
  const [codeErrors, setCodeErrors] = useState<ReactNode[]>([])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setErrors([])
    setCodeErrors([])

    if (smsCode === '') {
      setCodeErrors(['Please enter a valid verification code.'])
      return
    }

    setErrors(await submitVerificationCode(smsCode))
  }

  return (
    <form onSubmit={onSubmit}>
      <h2 className="mb-md heading-3">We&apos;ve sent a code to your mobile phone{phoneNumber && ` ${phoneNumber}`}.</h2>
      <TextField
        id="verificationCode"
        type="number"
        label="Enter your verification code"
        placeholder="Verification code"
        value={smsCode}
        errors={codeErrors}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSmsCode(e.target.value)}
      />
      <ResendVerificationCodePanel
        codeReRequestTime={codeReRequestTime}
        requestVerificationCode={requestVerificationCode}
      />
      {errors.length > 0 && (<ErrorMessages id="error" errors={errors} />)}
      <Button
        type="submit"
        text="Continue"
        iconName="arrow-right"
        animation="shiftRight"
      />
    </form>
  )
}

export default VerifyPhoneNumberForm
