import { useContext, useEffect, useState } from 'react'
import {
  resendSignUpCode,
  confirmSignUp,
  autoSignIn,
  sendUserAttributeVerificationCode,
  confirmUserAttribute,
} from 'aws-amplify/auth'
import Link from 'next/link'
import SessionContext from '../../../../contexts/session-context'
import CommonVerifyEmailForm from '../../common/VerifyEmailForm/verify-email-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const generalError = (
  <div>
    We are unable to complete your sign in. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const resendGeneralError = (
  <div>
    We are unable to send you a verification code. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const VerifyEmailForm = (
  {
    onPhoneNumberUnverified,
    onPhoneNumberVerified,
    onLoginRequired,
    codeReRequestTime = undefined,
  }: {
    onPhoneNumberUnverified: () => void,
    onPhoneNumberVerified: () => void,
    onLoginRequired: () => void,
    codeReRequestTime?: number,
  },
) => {
  const { refreshUser, user } = useContext(SessionContext)
  const [email, setEmail] = useState<string | null>(null)

  useEffect(() => {
    setEmail(sessionStorage.getItem('email'))
  }, [])

  const submitVerification = async (emailCode: string) => {
    if (email == null) {
      return ['Email is not set!']
    }

    try {
      if (!user?.email_verified) {
        const verificationResult = await confirmSignUp({
          username: email,
          confirmationCode: emailCode,
        })

        sessionStorage.removeItem('email')

        switch (verificationResult?.nextStep?.signUpStep) {
          case 'COMPLETE_AUTO_SIGN_IN':
            await autoSignIn()
            if (refreshUser) { await refreshUser() }
            onPhoneNumberUnverified()
            break
          case 'DONE':
          default:
            onLoginRequired()
        }
      } else {
        await confirmUserAttribute({
          userAttributeKey: 'email',
          confirmationCode: emailCode,
        })

        if (user.phone_number_verified !== 'true') {
          onPhoneNumberUnverified()
        } else {
          onPhoneNumberVerified()
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'CodeMismatchException':
          case 'NotAuthorizedException':
            return ['Please enter a valid verification code.']
          case 'ExpiredCodeException':
            return ['Your code has expired. Please request a new verification code.']
          case 'EmptyConfirmSignUpUsername':
            return ['You must be signed in to confirm your email.']
          case 'EmptyConfirmSignUpCode':
            return ['Verification code is required.']
          case 'LimitExceededException':
            return ['You have submitted too many verification codes. Please wait 1 hour before submitting another code.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occurred when trying to confirm sign up using a verification code. The method that caused this error is confirmSignUp.',
              error,
              cognitoMethod: 'confirmSignUp',
            })
            return [generalError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito occurred when trying to confirm sign up using a verification code. The method that caused this error is confirmSignUp.',
        error,
        cognitoMethod: 'confirmSignUp',
      })
      return [generalError]
    }
    return []
  }

  const requestVerification = async () => {
    if (email == null) {
      return ['Email is not set!']
    }

    try {
      if (!user) {
        await resendSignUpCode({
          username: email,
        })
      } else {
        await sendUserAttributeVerificationCode({ userAttributeKey: 'email' })
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'LimitExceededException':
            return ['You have requested too many verification codes. Please wait 1 hour before requesting another code.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occurred when requesting a new verification code. The method that caused this error is resendSignUpCode.',
              error,
              cognitoMethod: 'resendSignUpCode',
            })
            return [resendGeneralError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito occurred when requesting a new verification code. The method that caused this error is resendSignUpCode',
        error,
        cognitoMethod: 'resendSignUpCode',
      })
      return [resendGeneralError]
    }
    return []
  }

  return (
    <CommonVerifyEmailForm
      email={email}
      requestVerification={requestVerification}
      submitVerification={submitVerification}
      codeReRequestTime={codeReRequestTime}
    />
  )
}

export default VerifyEmailForm
