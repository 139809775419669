import {
  ReactNode,
  useEffect,
  useState,
  useContext,
} from 'react'
import { useRouter } from 'next/router'
import {
  FetchMFAPreferenceOutput,
  UpdateMFAPreferenceInput,
  fetchMFAPreference,
  updateMFAPreference,
} from 'aws-amplify/auth'
import {
  Button, Callout, Content, RadioField,
} from '@sh24/ui-components'
import SessionContext from '../../../../contexts/session-context'
import notifyError from '../../../../services/exception-notifier/exception-notifier'

const MfaModule = () => {
  const { push, query } = useRouter()
  const { user, sessionLoaded } = useContext(SessionContext)
  const [currentMfaPreference, setCurrentMfaPreference] = useState<FetchMFAPreferenceOutput|null>(null)
  const [mfaPreference, setMfaPreference] = useState<string|undefined>(undefined)
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(query.success === 'true')
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [errors, setErrors] = useState<ReactNode[]>([])

  const allOptions: {value: 'SMS'|'TOTP', text: string}[] = [
    { value: 'SMS', text: 'Text message verification' },
    { value: 'TOTP', text: 'Authenticator app' },
  ]

  const options = allOptions.filter(
    (option) => (currentMfaPreference?.enabled || []).includes(option.value),
  )

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setErrors([])
    try {
      const newMfaPreference: UpdateMFAPreferenceInput = {}
      if (currentMfaPreference?.enabled?.includes('SMS')) newMfaPreference.sms = 'ENABLED'
      if (currentMfaPreference?.enabled?.includes('TOTP')) newMfaPreference.totp = 'ENABLED'
      if (mfaPreference === 'SMS') newMfaPreference.sms = 'PREFERRED'
      if (mfaPreference === 'TOTP') newMfaPreference.totp = 'PREFERRED'

      await updateMFAPreference(newMfaPreference)
      setShowSuccessMessage(true)
    } catch (error) {
      setErrors(['An error occurred while updating your MFA preference'])
      notifyError(error)
    }
  }

  useEffect(() => {
    if (sessionLoaded && user) {
      fetchMFAPreference().then(
        (data) => {
          setCurrentMfaPreference(data)
          setMfaPreference(data.preferred)
        },
      )
    }
  }, [sessionLoaded])

  const addAuthenticatorText = (currentMfaPreference?.enabled || []).includes('TOTP') ? 'Change authenticator app' : 'Add authenticator app'

  const body = (
    <>
      {showConfirmationMessage && (
      <Callout
        titleColour="/successBold"
        backgroundColour="/successFill"
        title="Your authenticator app has been added."
        centered
      />
      )}
      <p>
        2-step verification is an added layer of security for your account.
        When you log in, we’ll ask you to enter a unique verification code.
        You can get this code sent in a text message or you can set up an authenticator app to manage your codes.
      </p>
      <form
        className="mb-lg"
        onSubmit={onSubmit}
      >
        <RadioField
          id="mfaPreference"
          selected={mfaPreference}
          options={options}
          errors={errors}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setMfaPreference(e.target.value)
            setShowSuccessMessage(false)
            setShowConfirmationMessage(false)
          }}
        />
        <Button
          className="mb-md"
          type="submit"
          text="Save changes"
        />
        {showSuccessMessage && (
        <Callout
          titleColour="/successBold"
          backgroundColour="/successFill"
          title="2-step verification method updated."
          centered
        />
        )}
      </form>
      <Button
        type="button"
        variation="secondary"
        text={addAuthenticatorText}
        iconName="arrow-right"
        animation="shiftRight"
        onClick={() => push('/account/settings/mfa/add-authenticator')}
      />
    </>
  )

  return (
    <Content
      width="standard"
      textAlign="left"
      content={[currentMfaPreference ? body : (<p>Loading...</p>)]}
    />
  )
}

export default MfaModule
