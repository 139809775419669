const productToBasketItem = (product) => {
  const selectedOption = product.options?.find((opt) => opt.selected)

  return {
    productId: product.productId,
    productName: product.productName,
    name: product.name,
    identifier: product.identifier,
    byline: product.byline,
    price: product.price,
    selectedOption: selectedOption ? {
      name: selectedOption.name,
      label: selectedOption.label,
      identifier: selectedOption.identifier,
      price: selectedOption.price,
    } : null,
    mandatory: product.mandatory,
    preselected: product.preselected,
    mutuallyExclusive: product.mutuallyExclusive,
    isPrimary: product.isPrimary,
    isSecondary: product.isSecondary,
    isExtra: product.isExtra,
  }
}

export default productToBasketItem
