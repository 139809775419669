import React, {
  useContext, useEffect, useState,
} from 'react'
import {
  signIn, signOut,
} from 'aws-amplify/auth'
import Link from 'next/link'
import SessionContext from '../../../../contexts/session-context'
import CommonVerifyPhoneNumberForm from '../../common/VerifyPhoneNumberForm/verify-phone-number-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'
import submitMfaCode from '../../../../utils/cognito/form-helpers/submit-mfa-code'

const generalResendError = (
  <div>
    We are unable to send you a verification code due to a technical issue. Please try again.
    If this problem continues, please{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const VerifyPhoneNumberForm = ({
  onEmailConfirmed,
  onEmailUnconfirmed,
} : {
  onEmailConfirmed: () => Promise<void>,
  onEmailUnconfirmed: () => Promise<void>,
},
) => {
  const { refreshUser } = useContext(SessionContext)
  const [user, setUser] = useState<
    {email: string | null, password: string | null, phoneNumber: string | null}
  >({ email: null, password: null, phoneNumber: null })

  // set credentials from sessionStorage used from previous log-in form.
  useEffect(() => {
    setUser({
      email: sessionStorage.getItem('email'),
      password: sessionStorage.getItem('password'),
      phoneNumber: sessionStorage.getItem('number'),
    })
  }, [])

  const resendSmsCode = async () => {
    if (!user.email) {
      return ['Email not set!']
    }
    if (!user.password) {
      return ['Password not set!']
    }
    try {
      await signOut()
      await signIn({ username: user.email, password: user.password })
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'LimitExceededException') {
          return ['You have requested too many verification codes. Please wait 1 hour before requesting another code.']
        }
        await notifyWithContext({
          message: 'An unexpected error from Cognito occurred when requesting a new verification code. The method that caused this error is signIn.',
          error,
          cognitoMethod: 'signIn',
        })
        return [generalResendError]
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito occurred when requesting a new verification code. The method that caused this error is signIn.',
        error,
        cognitoMethod: 'signIn',
      })
      return [generalResendError]
    }
    return []
  }

  return (
    <CommonVerifyPhoneNumberForm
      submitVerificationCode={submitMfaCode(
        {
          refreshUser,
          onEmailConfirmed,
          onEmailUnconfirmed,
        },
      )}
      requestVerificationCode={resendSmsCode}
      phoneNumber={user.phoneNumber ?? undefined}
    />
  )
}
export default VerifyPhoneNumberForm
