import { format } from 'date-fns'
import { HistoricalOrder, HistoricalOrdersData } from '@sh24/admin-api-js'
import {
  ByYearAndDate, OrderCard, Tag, ButtonLink,
} from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../utils/use-translations'
import { camelize, titleize } from '../../utils/helpers'
import InlineLink from '../InlineLink/inline-link'
import prepareTestData from './prepare-test-data'

const OrderSummaryTag = styled(Tag)`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.sm};
    margin-right: ${theme?.spacing?.sm};
    margin-bottom: ${theme?.spacing?.sm};
  `}
`
const iconMap: Record<string, string> = {
  stiTestOrder: 'swab-urine',
}

const ActionButton = ({ text, url }: { text: string, url: string }) => (
  <ButtonLink variation="primary" fullWidth iconPosition="none">
    <InlineLink
      text={text}
      url={url}
    />
  </ButtonLink>
)

const OrdersHistoryDisplay = ({ orders }: { orders: HistoricalOrdersData }) => {
  const translations = useTranslations()

  const renderBoltOns = (order: HistoricalOrder) => {
    if (order.extraProducts) {
      return order.extraProducts.map((sku: string) => ({
        title: translations[`orderCard.sku.${sku}.title`],
        description: translations[`orderCard.sku.${sku}.description`],
      }))
    }

    return []
  }

  const renderOrderCard = (order: HistoricalOrder) => {
    let actionButton

    if (order.resultsState) {
      actionButton = (
        <ActionButton
          text={translations[`orderCard.viewResultsButtonText.${order.resultsState}`]}
          url={`/account/results/${order.sh24Uid}`}
        />
      )
    }

    if (order.orderType === 'genitalDiagnosis' && order.orderState === 'requestedNewPhotos') {
      actionButton = (
        <ActionButton
          text={translations['orderCard.newPhotosButtonText']}
          url={`/orders/gd-more-photos-diagnosis/new?token=${order.accessToken}`}
        />
      )
    } else if (order.orderType === 'genitalWartsTreatment' && order.orderState === 'requestedNewPhotos') {
      actionButton = (
        <ActionButton
          text={translations['orderCard.newPhotosButtonText']}
          url={`/orders/gd-more-photos-treatment/new?token=${order.accessToken}`}
        />
      )
    } else if (order.orderType === 'genitalWartsTreatment' && order.orderState === 'followUp' && order.followupUrl) {
      actionButton = (
        <ActionButton
          text={translations['orderCard.followUpButtonText']}
          url={order.followupUrl}
        />
      )
    } else if (order.orderState === 'prescription') {
      actionButton = (
        <ActionButton
          text={translations['orderCard.viewPrescriptionButtonText']}
          url="/account/prescriptions"
        />
      )
    }

    const isCollection = order.fulfilmentMethod === 'clickAndCollect'
    const addressHeading = (isCollection && order.fulfilmentOption?.address)
      ? translations[`orderCard.addressHeading.${order.fulfilmentMethod}`]
      : null
    const address = isCollection ? order.fulfilmentOption?.address : null

    return (
      <OrderCard
        tagText={translations[`orderCard.${camelize(order.orderType)}`] || titleize(order.orderType)}
        tagIcon={iconMap[order.orderType] || 'tablet-capsule'}
        boltOnHeading="Extras"
        boltOnItems={renderBoltOns(order)}
        items={prepareTestData(order, translations)}
        extraInfo={translations[`orderCard.extraInfo.${order.orderType}`]}
        orderId={`${translations['orderCard.orderId']} ${order.sh24Uid}`}
        status={`${translations[`orderCard.${order.orderState}`] || order.orderState} ${format(order.updatedAt, 'dd LLLL yyyy')}`}
        addressHeading={addressHeading}
        address={address}
        bottomSection={actionButton}
      />
    )
  }

  const ordersDisplay = orders.orders
    .sort(({ createdAt: a }, { createdAt: b }) => b.valueOf() - a.valueOf())
    .map((order, index) => (
      {
        date: order.createdAt,
        content: renderOrderCard(order),
        previewContent: (
          <OrderSummaryTag
            key={order.sh24Uid}
            text={translations[`orderCard.${camelize(order.orderType)}`] || ''}
            icon={iconMap[order.orderType] || 'tablet-capsule'}
          />
        ),
        defaultOpen: index === 0,
      }
    ))

  return (
    <ByYearAndDate items={ordersDisplay} />
  )
}

export default OrdersHistoryDisplay
