import { ButtonLink, Callout } from '@sh24/ui-components'
import styled from 'styled-components'
import InlineLink from '../InlineLink/inline-link'
import useTranslations from '../../utils/use-translations'

interface PartnerNotificationOfferCardType {
  offerToken: string
}

const CtaButtonOuterWrapper = styled.div`
  div {
    a {
      justify-content: center;
    }
  }
`

const PartnerNotificationOfferCard = ({ offerToken }: PartnerNotificationOfferCardType) => {
  const translations = useTranslations()

  const ctaButton = (
    <CtaButtonOuterWrapper>
      <ButtonLink variation="primary" fullWidth>
        <InlineLink
          url={`/orders/partner-notification/new?token=${offerToken}`}
          text={translations['partnerNotificationOfferCard.btnText']}
        />
      </ButtonLink>
    </CtaButtonOuterWrapper>
  )

  return (
    <Callout
      backgroundColour="/primary300"
      title={translations['partnerNotificationOfferCard.title']}
      content={translations['partnerNotificationOfferCard.content']}
      ctaButton={ctaButton}
    />
  )
}

export default PartnerNotificationOfferCard
