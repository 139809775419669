import { useState, useEffect } from 'react'
import { OrderSummary } from '@sh24/ui-components'
import Image from '../../Image/image'
import documentToReactComponents from '../../../utils/document-to-react-components'
import {
  CURRENT_JOURNEY_KEY,
  getSession,
} from '../../../order-journeys/_v2/state/actions'

const OrderSummaryModule = ({
  image,
  intro,
  heading,
  addressHeading,
  deliveryMethodHeading,
  deliveryHeading,
  deliveryWindow,
  detailsHeading,
  moreInformation,
}) => {
  const [orderData, setOrderData] = useState({})
  const introComponents = documentToReactComponents(intro)
  const moreInfoComponents = documentToReactComponents(moreInformation)
  const summaryImage = (
    <Image
      image={image}
      maxHeight={180}
      maxWidth={180}
      layout="fill"
    />
  )

  useEffect(() => {
    const sessionKey = window.sessionStorage.getItem(CURRENT_JOURNEY_KEY)
    const session = getSession({ sessionKey })
    const {
      basket,
      deliveryAddress,
      fulfilmentMethod,
      fulfilmentMethodSelection,
    } = session?.context || {}

    const isCollection = fulfilmentMethod === 'collection'

    setOrderData({
      deliveryMethod: isCollection ? null : fulfilmentMethodSelection?.name,
      deliveryWindow: isCollection ? null : deliveryWindow,
      address: isCollection ? fulfilmentMethodSelection : deliveryAddress,
      details: basket?.map((item) => ({
        name: item.productName,
        byline: item.byline,
        option: item.selectedOption?.label,
      })),
    })
  }, [])

  return (
    <OrderSummary
      image={summaryImage}
      intro={introComponents}
      heading={heading}
      addressHeading={addressHeading}
      deliveryMethodHeading={deliveryMethodHeading}
      deliveryHeading={deliveryHeading}
      detailsHeading={detailsHeading}
      moreInformation={moreInfoComponents}
      {...orderData}
    />
  )
}

export default OrderSummaryModule
