import styled from 'styled-components'
import { BreakpointHelpers } from '@sh24/ui-components'
import { PrepSubscriptionAvailability, Consultation } from '@sh24/admin-api-js'
import Information from './Information/information'
import useTranslations from '../../../utils/use-translations'
import AvailableServicesButtons from './ReorderButtons/available-services-buttons'
import InlineLink from '../../InlineLink/inline-link'

interface OrderAvailabilityContainerType {
  availableServices: PrepSubscriptionAvailability | null,
  prepConsultation?: Consultation | null
}

type AvailableServiceType = 'prepOrder' | 'stiTestOrder' | 'prepConsultation'

const { mobileOnly, tabletUp } = BreakpointHelpers

const Container = styled.div<{ prepConsultationOnly: boolean }>`
  ${({ prepConsultationOnly }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    ${tabletUp(`
        ${prepConsultationOnly ? 'width: 60%;' : 'width: 80%;'}
      `)}


    ${mobileOnly(`
      flex-direction: column;
    `)}

    h3 {
      text-align: center;
    }
  `}
`

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CtaAndInfoWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    height: auto;
    gap: ${theme?.spacing?.md};

    ${tabletUp(`
        flex-direction: row;
      `)}

    ${mobileOnly(`
      flex-direction: column;
    `)}
  `}
`

const OrderAvailabilityContainer = ({
  availableServices,
  prepConsultation = null,
}: OrderAvailabilityContainerType) => {
  const translations = useTranslations()

  const extractAvailableServices = (availableServicesToOrder: Record<AvailableServiceType, boolean>): Array<string> => (
    Object
      .keys(availableServicesToOrder)
      .filter((service) => availableServicesToOrder[service as AvailableServiceType])
  )

  const { ...servicesAvailable } = availableServices
  delete servicesAvailable.repeatHivTest

  const servicesAvailableToOrder = (servicesAvailable && extractAvailableServices(servicesAvailable)) || []

  const headingText = translations['prepDashboard.orderAvailability.availableToOrderHeading']
  const prepConsultationOnly = (servicesAvailableToOrder.length === 1) && (servicesAvailableToOrder.includes('prepConsultation'))

  return (
    <Container prepConsultationOnly={prepConsultationOnly}>
      <OuterWrapper>
        {servicesAvailableToOrder && servicesAvailableToOrder.length > 0 && (
          <h3>{headingText}</h3>
        )}
        <CtaAndInfoWrapper>
          {servicesAvailableToOrder && servicesAvailableToOrder.length > 0 && (
            <AvailableServicesButtons
              availableServices={servicesAvailableToOrder}
              prepConsultation={prepConsultation}
            />
          )}
          {!prepConsultationOnly && (
            <Information
              servicesAvailableToOrder={servicesAvailableToOrder.length > 0}
            />
          )}
        </CtaAndInfoWrapper>
        <div className="row row-centered mt-md">
          <InlineLink
            url="/prep/prep-sh24"
            text={translations['prepDashboard.learnMoreAboutOurServiceLinkText']}
          />
        </div>
      </OuterWrapper>
    </Container>
  )
}

export default OrderAvailabilityContainer
