import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import VerifyPhoneNumberForm from '../../../Forms/SignUp/VerifyPhoneNumberForm/verify-phone-number-form'

const SignUpVerifyPhoneNumberModule = () => {
  const { push } = useRouter()

  const onCodeVerified = async () => {
    const redirectUrl = await sessionStorage.getItem('redirectUrl')
    sessionStorage.removeItem('redirectUrl')
    await push(redirectUrl || '/account/home')
  }

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <VerifyPhoneNumberForm
          key="form"
          onCodeVerified={onCodeVerified}
        />,
      ]
    }
    />
  )
}

export default SignUpVerifyPhoneNumberModule
