import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/mobile'
import dynamic from 'next/dynamic'
import {
  BreakpointHelpers, Button, ErrorMessages, TextField, SelectField, OptionType,
} from '@sh24/ui-components'
import { isMobilePhoneNumber } from '../../../../order-journeys/_v2/validation/validation-functions'
import countryForBrand from '../../../../utils/country-for-brand'

const DynamicSelectField = dynamic(
  () => import('@sh24/ui-components').then((components) => components.SelectField),
  { ssr: false },
) as typeof SelectField

const { tabletUp } = BreakpointHelpers

const Container = styled.div`
  ${tabletUp(`
    width: 100%;
    display: flex;
  `)}
`

const CodeWrapper = styled.div`
  ${({ theme }) => `
    ${tabletUp(`
      flex-basis: auto;
      flex-shrink: 0;
      width: max-content;
      margin-right: ${theme?.spacing?.base};
    `)}
  `}
`

const TelWrapper = styled.div`
  ${tabletUp(`
    flex-grow: 1;
  `)}
`

const COUNTRY_CODE_OPTIONS = [
  { text: 'United Kingdom +44', value: 'GB' },
  { text: 'Ireland +353', value: 'IE' },
]

const currentCountry = countryForBrand(process.env.NEXT_PUBLIC_SITE)

// sort country code options by putting the current country first
COUNTRY_CODE_OPTIONS.sort((a, b) => {
  if (a.value === currentCountry) return -1
  if (b.value === currentCountry) return 1
  return 0
})

const COUNTRY_CODES = new Map<CountryCode, string>([
  ['GB', '+44'],
  ['IE', '+353'],
])

const PhoneNumberForm = (
  {
    submitPhoneNumber,
  }: {
    submitPhoneNumber: (phoneNumber: string) => Promise<ReactNode[]>,
  }) => {
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>(currentCountry)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberErrors, setPhoneNumberErrors] = useState<string[]>([])
  const [errors, setErrors] = useState<ReactNode[]>([])

  const selectedCountryCode = COUNTRY_CODE_OPTIONS.find((option) => option.value === countryCode)
  const selectedValue = selectedCountryCode
    ? {
      label: selectedCountryCode.text,
      value: selectedCountryCode.value,
    }
    : null

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setPhoneNumberErrors([])
    setErrors([])

    const prefix = countryCode ? COUNTRY_CODES.get(countryCode) : ''
    const fullNumber = `${prefix}${phoneNumber}`

    if (!Array.from(COUNTRY_CODES.keys()).some(
      (code) => isMobilePhoneNumber({ input: fullNumber, countryCode }, code))
    ) {
      setPhoneNumberErrors(['Please enter a valid phone number. We can only accept UK, Ireland or Channel Islands phone numbers.'])
      return
    }

    const parsedNumber = parsePhoneNumber(fullNumber, countryCode)
    const formattedNumber = parsedNumber.format('E.164')

    setErrors(await submitPhoneNumber(formattedNumber))
  }

  return (
    <form onSubmit={onSubmit}>
      <h2 className="mb-md heading-3">What is your mobile phone number</h2>
      <p>
        This must be your own personal number.<br />
        We&apos;ll send you a verification code every time you log in to your account
        as an extra security measure.<br />
        We only accept UK, Ireland and Channel Island numbers. Please select country code from the dropdown menu.
      </p>
      {phoneNumberErrors.length > 0 && (<ErrorMessages id="phoneNumberError" errors={phoneNumberErrors} />)}
      <Container>
        <CodeWrapper>
          <DynamicSelectField
            id="countryCode"
            placeholder="Select your country code"
            value={selectedValue as OptionType}
            options={COUNTRY_CODE_OPTIONS}
            onChange={(option: OptionType | null) => { setCountryCode(option?.value as CountryCode) }}
          />
        </CodeWrapper>
        <TelWrapper>
          <TextField
            id="phoneNumber"
            type="tel"
            placeholder="+XXXXXXXXXXXX"
            value={phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
          />
        </TelWrapper>
      </Container>
      {errors.length > 0 && (<ErrorMessages id="error" errors={errors} />)}
      <Button
        type="submit"
        variation="secondary"
        text="Send code"
      />
    </form>
  )
}

export default PhoneNumberForm
