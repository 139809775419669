import React, { useContext, useEffect } from 'react'
import {
  AccountSelection,
  Button,
  Callout,
  FieldTitle,
  Icon,
  Info,
  OrderJourneyContent,
} from '@sh24/ui-components'
import { signOut } from 'aws-amplify/auth'
import type { Image as ImageType } from '@sh24/contentful-service'
import { useRouter } from 'next/router'
import SessionContext from '../../../contexts/session-context'
import useTranslations from '../../../utils/use-translations'
import replaceTranslationTokens from '../../../utils/replace-translation-tokens'
import Image from '../../Image/image'

const AccountSelectionModule = ({
  loggedInTitle,
  loggedInLabel,
  loggedOutTitle,
  loggedOutLabel,
  topSectionImage = undefined,
  topSectionHeader,
  topSectionBody,
  topSectionBullets,
  topSectionPrimaryButtonText,
  topSectionSecondaryButtonText,
  separatorText,
  bottomSectionHeader,
  bottomSectionBody,
  bottomSectionPrimaryButtonText,
  context,
  dispatch,
  nextPage,
  setHideContinueButton,
}: {
  loggedInTitle: string
  loggedInLabel: string
  loggedOutTitle: string
  loggedOutLabel: string
  topSectionImage?: ImageType
  topSectionHeader: string
  topSectionBody: string
  topSectionBullets: string[]
  topSectionPrimaryButtonText: string
  topSectionSecondaryButtonText: string
  separatorText: string
  bottomSectionHeader: string
  bottomSectionBody: string
  bottomSectionPrimaryButtonText: string
  context: Record<string, unknown>
  dispatch: (key: string, value: unknown) => void
  nextPage: () => void
  setHideContinueButton: (value: boolean) => void
}) => {
  const { user, setUser, sessionLoaded } = useContext(SessionContext)
  const translations = useTranslations()
  const loggedInText = replaceTranslationTokens({
    translations,
    translationKey: 'accountSelection.loggedInText',
    values: [user?.email],
  })
  const { push, asPath } = useRouter()

  useEffect(() => (
    () => setHideContinueButton(false)
  ), [])

  useEffect(() => {
    if (sessionLoaded) {
      if (user) {
        dispatch('serviceUserFlow', 'account')
        dispatch('username', user.sub)
        dispatch('serviceUserEmailVerified', user.email_verified === 'true')
        dispatch('serviceUserPhoneNumberVerified', user.phone_number_verified === 'true')
      } else {
        dispatch('username', null)
        dispatch('serviceUserEmailVerified', null)
        dispatch('serviceUserPhoneNumberVerified', null)
        setHideContinueButton(true)
      }
    }
  }, [user, sessionLoaded])

  const accountButtonHandler = async (redirectUrl: string) => {
    sessionStorage.setItem('redirectUrl', asPath)
    await push(redirectUrl)
  }

  const loginHandler = async () => accountButtonHandler('/account/login')

  const signupHandler = async () => accountButtonHandler('/account/sign-up/email')

  const continueHandler = async () => {
    dispatch('serviceUserFlow', 'guest')
    nextPage()
  }

  if (!sessionLoaded) {
    return (
      <OrderJourneyContent>
        <div className="row row-centered">
          <Icon name="loading" width={48} height={48} animation="spin" />
        </div>
      </OrderJourneyContent>
    )
  }

  const title = user ? loggedInTitle : loggedOutTitle
  const label = user ? loggedInLabel : loggedOutLabel
  const image = topSectionImage
    ? <Image image={topSectionImage} maxWidth={topSectionImage.srcWidth} maxHeight={topSectionImage.srcHeight} />
    : null

  return (
    <OrderJourneyContent>
      <div className="mb-md">
        {title && <FieldTitle text={title} />}
        {label && <Info>{label}</Info>}
        {user
          ? (
            <Callout
              backgroundColour="/primary200"
              iconBackgroundColour="/white"
              icon="user"
              content={loggedInText}
              ctaButton={(
                <Button
                  text="Log out"
                  variation="text"
                  onClick={async () => {
                    await signOut()
                    setUser?.(null)
                  }}
                />
                )}
            />
          )
          : (
            <AccountSelection
              topSectionImage={image}
              topSectionHeader={topSectionHeader}
              topSectionBody={topSectionBody}
              topSectionBullets={topSectionBullets}
              topSectionPrimaryButtonText={topSectionPrimaryButtonText}
              topSectionPrimaryButtonHandler={signupHandler}
              topSectionSecondaryButtonText={topSectionSecondaryButtonText}
              topSectionSecondaryButtonHandler={loginHandler}
              separatorText={separatorText}
              bottomSectionHeader={bottomSectionHeader}
              bottomSectionBody={bottomSectionBody}
              bottomSectionPrimaryButtonText={bottomSectionPrimaryButtonText}
              bottomSectionPrimaryButtonHandler={continueHandler}
              mandatory={!!context?.user_accounts_mandatory}
            />
          )}
      </div>
    </OrderJourneyContent>
  )
}

export default AccountSelectionModule
