import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import PasswordForm from '../../../Forms/SignUp/PasswordForm/password-form'

const SignUpPasswordModule = () => {
  const { push } = useRouter()

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <PasswordForm
          key="form"
          onConfirmSignUp={() => push('/account/sign-up/verify-email')}
        />,
      ]
    }
    />
  )
}

export default SignUpPasswordModule
