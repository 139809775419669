import {
  ReactNode,
  useEffect,
  useState,
  useContext,
} from 'react'
import { useRouter } from 'next/router'
import {
  setUpTOTP,
  verifyTOTPSetup,
  updateMFAPreference,
} from 'aws-amplify/auth'
import QRCode from 'react-qr-code'
import { Button, TextField, Content } from '@sh24/ui-components'
import SessionContext from '../../../../contexts/session-context'
import notifyError from '../../../../services/exception-notifier/exception-notifier'

const MfaAddAuthenticatorModule = () => {
  const { user, sessionLoaded } = useContext(SessionContext)
  const [totpCode, setTotpCode] = useState('')
  const [errors, setErrors] = useState<ReactNode[]>([])
  const [totpUri, setTotpUri] = useState<string|null>(null)
  const { push } = useRouter()

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setErrors([])
    try {
      await verifyTOTPSetup({ code: totpCode })
      await updateMFAPreference({ totp: 'PREFERRED' })
      push('/account/settings/mfa?success=true')
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'InvalidParameterException':
          case 'EnableSoftwareTokenMFAException': {
            setErrors(['Please enter a valid authenticator code'])
            break
          }
          default: {
            setErrors(['An unexpected error occured while submitting your code'])
            notifyError(error)
          }
        }
      }
    }
  }

  useEffect(() => {
    const setResponseObject = async (email: string) => {
      const totpSetupDetails = await setUpTOTP()
      const setupUri = totpSetupDetails.getSetupUri('SH24', email)
      setTotpUri(setupUri.href)
    }

    if (sessionLoaded && user) {
      setResponseObject(user.email)
    }
  }, [sessionLoaded])

  const body = (
    <>
      <h2 className="heading-3">Step 1.</h2>
      <p>Scan this QR code in your authenticator app</p>
      {totpUri && (
      <>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '50%', width: '50%' }}
          value={totpUri}
          viewBox="0 0 256 256"
        />
        <p><a href={totpUri}>Add authenticator directly if using mobile</a></p>
      </>
      )}
      <h2 className="heading-3">Step 2.</h2>
      <p>Enter code from your authenticator app</p>
      <form onSubmit={onSubmit}>
        <TextField
          id="totpCode"
          type="number"
          label=""
          placeholder="MFA code"
          value={totpCode}
          errors={errors}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTotpCode(e.target.value)}
        />
        <Button
          type="submit"
          text="Confirm"
          iconName="arrow-right"
          animation="shiftRight"
        />
      </form>
    </>
  )

  return (
    <Content
      width="standard"
      textAlign="left"
      content={[totpUri ? body : (<p>Loading...</p>)]}
    />
  )
}

export default MfaAddAuthenticatorModule
