import { useContext } from 'react'
import styled from 'styled-components'
import { BreakpointHelpers } from '@sh24/ui-components'
import { groupBy } from 'lodash'
import AccountContext from '../../../../contexts/account-context'
import useTranslations from '../../../../utils/use-translations'
import ensureOrdersLoaded from '../../../../utils/ensure-orders-loaded'
import OrderStatusesContainer from '../../../AccountHomepage/OrderStatusUpdate/order-statuses-container'

const { mobileOnly, tabletUp } = BreakpointHelpers

const Centered = styled.div`
  text-align: center;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StatusWrapper = styled.div`
    justify-content: center;
  width: 100%;
  margin: auto;

  ${tabletUp(`
  width: 80%;
      `)}

  ${mobileOnly(`
    `)}
`

const AccountOrderUpdatesModule = () => {
  const { ordersContext } = useContext(AccountContext)
  const { orders } = ordersContext
  ensureOrdersLoaded()

  const translations = useTranslations()

  if (!orders) {
    return (
      <Centered>
        <p>{translations['accountOrderUpdates.loadingOrderUpdates']}</p>
      </Centered>
    )
  }

  const ordersSortedByDate = orders
    .orders
    .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
  const groupedByOrderType = groupBy(ordersSortedByDate, 'orderType')
  const stiTestOrders = groupedByOrderType.stiTestOrder

  if (!stiTestOrders || stiTestOrders.length === 0) {
    return (
      <Centered>
        <p>{translations['accountOrderUpdates.noOrderUpdates']}</p>
      </Centered>
    )
  }

  return (
    <InnerContainer>
      <StatusWrapper>
        <OrderStatusesContainer
          lastStiTestOrder={stiTestOrders[0]}
        />
      </StatusWrapper>
    </InnerContainer>
  )
}

export default AccountOrderUpdatesModule
