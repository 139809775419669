import { Accordion, Button } from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../../utils/use-translations'
import { formatTimeWithoutAMPM } from './utils'
import { Slot, State } from './types'
import NoSlotsAlert from './no-slots-alert'

const BookingViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`

const SlotGroupContainer = styled.div``

const SlotGroupTitle = styled.div`
  text-align: center;
`

const Slots = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.base};
`

const StyledAccordionWrapper = styled.div`
  min-width: 300px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black200};
`

const timeSlotText = (slot: Slot) => (
  [
    new Date(slot.start_at),
    new Date(slot.end_at),
  ].map((time) => formatTimeWithoutAMPM(time)).join(' - ')
)

const SlotGroup = ({
  title,
  slotButtons,
} : {
  title: string,
  slotButtons: Array<JSX.Element>,
}) => {
  if (slotButtons.length !== 0) {
    return (
      <SlotGroupContainer className="mt-base mb-base">
        <SlotGroupTitle className="body-lg semi-bold">
          {title}
        </SlotGroupTitle>
        <Slots className="pb-base pt-base">
          {slotButtons}
        </Slots>
      </SlotGroupContainer>
    )
  }

  return null
}

const BookingView = ({
  loading,
  onBook,
  selectedSlot,
  slotsByDate,
} : {
  loading: State['loading'],
  onBook: (arg0: Slot) => Promise<void>,
  selectedSlot: State['selectedSlot'],
  slotsByDate: State['slotsByDate'],
}) => {
  const translations = useTranslations()
  const morningTitle = translations['consultationAppointmentBookingModule.morning']
  const afternoonTitle = translations['consultationAppointmentBookingModule.afternoon']

  const isSelected = (slot: Slot) => (
    slot.uid === selectedSlot?.uid
  )

  const slotButton = (slot: Slot) => (
    <Button
      key={slot.uid}
      variation="secondary"
      text={timeSlotText(slot)}
      onClick={() => onBook(slot)}
      loading={isSelected(slot) && loading}
      disabled={loading}
    />
  )

  return (
    <BookingViewContainer className="mt-md">
      {(slotsByDate.length === 0) ? <NoSlotsAlert /> : null}
      {slotsByDate.map(({
        date,
        morningSlots,
        afternoonSlots,
      }) => (
        <StyledAccordionWrapper key={date} className="pb-base">
          <Accordion
            title={date}
            content={(
              <div>
                <SlotGroup
                  title={morningTitle}
                  slotButtons={morningSlots.map((slot: Slot) => slotButton(slot))}
                />
                <SlotGroup
                  title={afternoonTitle}
                  slotButtons={afternoonSlots.map((slot: Slot) => slotButton(slot))}
                />
              </div>
            )}
          />
        </StyledAccordionWrapper>
      ),
      )}
    </BookingViewContainer>
  )
}

export default BookingView
