import { useContext } from 'react'
import {
  signIn, signOut, fetchUserAttributes, resendSignUpCode,
} from 'aws-amplify/auth'
import SessionContext from '../../../../contexts/session-context'
import CommonLoginForm from '../../common/LoginForm/login-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const LoginForm = (
  {
    onVerifiedEmailWithSmsMfa,
    onVerifiedEmailWithTotpMfa,
    onUnverifiedEmail,
    onMfaNotSetUp,
  }: {
    onVerifiedEmailWithSmsMfa: () => void,
    onVerifiedEmailWithTotpMfa: () => void,
    onUnverifiedEmail: () => void,
    onMfaNotSetUp: () => void,
  },
) => {
  const { refreshUser, setUser } = useContext(SessionContext)

  const submitLogin = async (username: string, password: string) => {
    try {
      await signOut()
      setUser?.(null)
      const result = await signIn({ username, password })

      switch (result?.nextStep?.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_SMS_CODE': {
          sessionStorage.setItem('email', username)
          sessionStorage.setItem('password', password)
          if (result.nextStep.codeDeliveryDetails?.destination) {
            sessionStorage.setItem('number', result.nextStep.codeDeliveryDetails.destination)
          }
          onVerifiedEmailWithSmsMfa()
          break
        }
        case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE': {
          onVerifiedEmailWithTotpMfa()
          break
        }
        case 'CONFIRM_SIGN_UP':
          sessionStorage.setItem('email', username)
          await resendSignUpCode({
            username,
          })
          onUnverifiedEmail()
          break
        case 'DONE':
          if (refreshUser) { await refreshUser() }
          if ((await fetchUserAttributes()).email_verified !== 'true') {
            sessionStorage.setItem('email', username)
            await resendSignUpCode({
              username,
            })
            onUnverifiedEmail()
          } else {
            onMfaNotSetUp()
          }
          break
        default:
          await notifyWithContext({
            message: 'An unexpected response from Cognito occured during sign-in. The method that caused this response is signIn.',
            response: result,
            cognitoMethod: 'signIn',
          })
          return { login: true }
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'LimitExceededException':
            return { submitLimitExceededError: true }
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occured during sign-in. The method that caused this error is signIn.',
              error,
              cognitoMethod: 'signIn',
            })
            return { login: true }
        }
      }
    }
    return {}
  }

  return <CommonLoginForm submitLogin={submitLogin} />
}

export default LoginForm
