import { ButtonLink, ConsultationManagementCard } from '@sh24/ui-components'
import { Consultation } from '@sh24/admin-api-js'
import InlineLink from '../../InlineLink/inline-link'
import { formatAppointmentTimes, formatAppointmentDate } from '../../../utils/consultation'
import useTranslations from '../../../utils/use-translations'

export interface ConsultationBookedCardProps{
  consultation: Consultation
}

const ConsultationBookedCard = ({
  consultation,
} : ConsultationBookedCardProps) => {
  const appointmentStart = consultation.appointmentStart && new Date(consultation.appointmentStart)
  const appointmentEnd = consultation.appointmentEnd && new Date(consultation.appointmentEnd)
  const formattedAppointmentDate = appointmentStart && formatAppointmentDate({ appointmentStart })
  const formattedAppointmentTime = (appointmentStart && appointmentEnd)
    && formatAppointmentTimes({ appointmentStart, appointmentEnd })

  const translations = useTranslations()

  const manageYourAppointmentButton = (
    <ButtonLink iconPosition="none">
      <InlineLink
        url={consultation.schedulingUrl}
        text={translations['prepDashboard.consultationCard.manageYourAppointmentButtonText']}
      />
    </ButtonLink>
  )

  return (
    <ConsultationManagementCard
      heading={translations['prepDashboard.consultationCard.title']}
      appointmentTitle={translations['prepDashboard.consultationCard.appointmentTitle']}
      appointmentDate={formattedAppointmentDate}
      appointmentTime={formattedAppointmentTime}
      appointmentInfoText={translations['prepDashboard.consultationCard.appointmentInfo']}
      manageAppointmentButton={manageYourAppointmentButton}
    />
  )
}

export default ConsultationBookedCard
