import { signUp } from 'aws-amplify/auth'
import Link from 'next/link'
import CommonPasswordForm from '../../common/PasswordForm/password-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const generalError = (
  <div>
    We are unable to complete your sign up. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const PasswordForm = ({ onConfirmSignUp }: { onConfirmSignUp: () => void }) => {
  const submitPassword = async (password: string) => {
    const email = sessionStorage.getItem('email')

    if (email == null) {
      return ['Email is not set!']
    }

    try {
      const result = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: true,
        },
      })

      if (result?.nextStep?.signUpStep === 'CONFIRM_SIGN_UP') {
        onConfirmSignUp()
      } else {
        await notifyWithContext({
          message: 'An unexpected response from Cognito occured during sign-up. The method that caused this response is signUp.',
          response: result,
          cognitoMethod: 'signUp',
        })
        return [generalError]
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'UsernameExistsException':
            onConfirmSignUp()
            break
          case 'InvalidPasswordException':
            return [`Please try a different password. ${error.message}`]
          case 'LimitExceededException':
            return ['You have tried to sign up too many times. Please wait 1 hour before trying again.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occured during sign-up. The method that caused this error is signUp.',
              error,
              cognitoMethod: 'signUp',
            })
            return [generalError]
        }
      }
      await notifyWithContext({
        message: 'Unexpected error from Cognito occured during sign-up. The method that caused this error is signUp.',
        error,
        cognitoMethod: 'signUp',
      })
      return [generalError]
    }
    return []
  }

  return (
    <CommonPasswordForm submitPassword={submitPassword} />
  )
}

export default PasswordForm
