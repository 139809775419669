import { sendUserAttributeVerificationCode, updateUserAttribute } from 'aws-amplify/auth'
import Link from 'next/link'
import CommonPhoneNumberForm from '../../common/PhoneNumberForm/phone-number-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const generalError = (
  <div>
    We are unable to update your phone number due to a technical issue. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const PhoneNumberForm = ({ onUnverifiedPhoneNumber }: { onUnverifiedPhoneNumber: () => void }) => {
  const submitPhoneNumber = async (phoneNumber: string) => {
    try {
      await updateUserAttribute({
        userAttribute: {
          attributeKey: 'phone_number',
          value: phoneNumber,
        },
      })

      await sendUserAttributeVerificationCode({
        userAttributeKey: 'phone_number',
      })

      onUnverifiedPhoneNumber()
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'LimitExceededException':
            return ['You have tried to update your phone number too many times. Please wait 1 hour before trying again.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occurred when attempting to update a users phone number. The method that caused this error is either updateUserAttribute or sendUserAttributeVerificationCode.',
              error,
              cognitoMethod: 'updateUserAttribute or sendUserAttributeVerificationCode',
            })
            return [generalError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito occurred when attempting to update a users phone number. The method that caused this error is either updateUserAttribute or sendUserAttributeVerificationCode.',
        error,
        cognitoMethod: 'updateUserAttribute or sendUserAttributeVerificationCode',
      })
      return [generalError]
    }

    return []
  }

  return <CommonPhoneNumberForm submitPhoneNumber={submitPhoneNumber} />
}

export default PhoneNumberForm
