import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import EmailForm from '../../../Forms/SignUp/EmailForm/email-form'

const SignUpEmailModule = () => {
  const { push } = useRouter()

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <EmailForm
          key="form"
          onEnterPassword={() => push('/account/sign-up/password')}
        />,
      ]
    }
    />
  )
}

export default SignUpEmailModule
