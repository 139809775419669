import { signOut } from 'aws-amplify/auth'
import { useContext } from 'react'
import CommonEmailForm from '../../common/EmailForm/email-form'
import SessionContext from '../../../../contexts/session-context'

const EmailForm = ({ onEnterPassword }: { onEnterPassword: () => void }) => {
  const { setUser } = useContext(SessionContext)
  const submitEmail = async (email: string) => {
    sessionStorage.setItem('email', email)
    await signOut()
    setUser?.(null)
    onEnterPassword()

    return []
  }

  const intro = (
    <>
      <p>
        Register an account for secure online access.
      </p>
      <ul>
        <li>Check your order status</li>
        <li>Manage your prescriptions and test results</li>
        <li>Get advice and support online</li>
      </ul>
    </>
  )

  return (
    <CommonEmailForm
      submitEmail={submitEmail}
      heading="Create an account"
      intro={intro}
    />
  )
}

export default EmailForm
