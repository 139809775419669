import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import {
  Button, Callout, ErrorMessages, TextField,
} from '@sh24/ui-components'
import { ErrorBoundary } from '@sentry/react'

interface ErrorsType {
  username?: boolean,
  password?: boolean,
  login?: boolean,
  submitLimitExceededError?: boolean,
}

const Container = styled.div`
  display: flex;
`

const SubmitButtonContainer = styled.div`
  flex-grow: 1;
  text-align: right;
`

const getEmailErrorMessages = (errors: ErrorsType): (JSX.Element | string)[] => (
  (errors.username ? ['Email is required.'] : [])
)

const getPasswordErrorMessages = (errors: ErrorsType): (JSX.Element | string)[] => (
  (errors.password ? ['Password is required.'] : [])
)

const getSubmitLimitExceededErrorMessages = (errors: ErrorsType): (JSX.Element | string)[] => (
  (errors.submitLimitExceededError ? ['You have attempted to sign in too many times. Please wait 1 hour before trying again..'] : [])
)
const getGeneralErrorMessages = (errors: ErrorsType): (JSX.Element | string)[] => (
  (errors.login
    ? [
      <div>
        We are unable to log you in. Please check your email and password have been entered correctly, or{' '}
        <Link href="/account/reset-password/email" prefetch={false}>
          reset your password
        </Link> to log in to your account.
        <br />
        If this problem continues, please try later or&nbsp;
        <Link href="/contact-us" prefetch={false}>
          contact us.
        </Link>
      </div>,
    ]
    : [])
)

const LoginForm = (
  {
    submitLogin,
  }: {
    submitLogin: (username: string, password: string) => Promise<ErrorsType>,
  },
) => {
  const [showLoginRequiredMessage, setLoginRequiredMessage] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<ErrorsType>({})

  useEffect(() => {
    setLoginRequiredMessage(sessionStorage.getItem('showLoginRequiredMessage') === 'true')
    sessionStorage.removeItem('showLoginRequiredMessage')
  }, [])

  const submitCredentials = async (e: React.FormEvent) => {
    e.preventDefault()
    setErrors({})

    const credentialErrors: {
      username: boolean,
      password: boolean,
    } = { username: false, password: false }

    if (!username || !password) {
      if (!username) {
        credentialErrors.username = true
      }
      if (!password) {
        credentialErrors.password = true
      }
      setErrors({ ...errors, ...credentialErrors })
      return
    }

    setErrors(await submitLogin(username, password))
  }
  const generalErrorMessages = getGeneralErrorMessages(errors)
  const submitLimitExceededErrorMessages = getSubmitLimitExceededErrorMessages(errors)

  return (
    <ErrorBoundary>
      {
        showLoginRequiredMessage && (
          <Callout
            titleColour="/successBold"
            backgroundColour="/successFill"
            title="Email confirmed, please log in again."
            centered
          />
        )
      }
      <form onSubmit={submitCredentials}>
        <h2 className="mb-md heading-3">Log in to your account</h2>
        <TextField
          id="email"
          type="email"
          label="Email"
          placeholder="Email"
          value={username}
          errors={getEmailErrorMessages(errors)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
        />
        <TextField
          id="password"
          type="password"
          label="Password"
          placeholder="Password"
          value={password}
          errors={getPasswordErrorMessages(errors)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value)
          }}
        />
        {submitLimitExceededErrorMessages && (<ErrorMessages id="errors" errors={getSubmitLimitExceededErrorMessages(errors)} />)}
        {generalErrorMessages && (<ErrorMessages id="errors" errors={getGeneralErrorMessages(errors)} />)}
        <Container>
          <div>
            <div className="mb-sm">
              <Link href="/account/reset-password/email" prefetch={false}>
                Forgot password?
              </Link>
            </div>
            <div>
              <Link href="/account/sign-up/email" prefetch={false}>
                Sign up for an account.
              </Link>
            </div>
          </div>
          <SubmitButtonContainer>
            <Button
              type="submit"
              text="Submit"
              iconName="arrow-right"
              animation="shiftRight"
            />
          </SubmitButtonContainer>
        </Container>
      </form>
    </ErrorBoundary>
  )
}

export default LoginForm
