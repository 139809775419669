import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import { VerifyEmailForm } from '../../../Forms/ResetPassword'

const ResetPasswordVerifyEmailModule = () => {
  const { push } = useRouter()

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <VerifyEmailForm
          key="form"
          onSuccessfulSubmit={() => push('/account/reset-password/new-password')}
        />,
      ]
    }
    />
  )
}

export default ResetPasswordVerifyEmailModule
