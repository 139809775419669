import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import { EmailForm } from '../../../Forms/ResetPassword'

const ResetPasswordEmailModule = () => {
  const { push } = useRouter()

  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <EmailForm
          key="form"
          onConfirmResetPassword={() => push('/account/reset-password/verify-email')}
        />,
      ]
    }
    />
  )
}

export default ResetPasswordEmailModule
