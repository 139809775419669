import styled from 'styled-components'
import {
  ButtonLink, ButtonStyleVariationType,
} from '@sh24/ui-components'
import InlineLink from '../../InlineLink/inline-link'

const WideButtonLinkContainer = styled.div<{ info?: boolean }>`
  ${({ theme, info }) => `
    margin-bottom ${theme?.spacing?.base};

    ${info ? `
      text-align: center;
      margin-bottom: ${theme?.spacing?.md};
      a {
        text-decoration: underline;
      }
    ` : `
      a {
        width: 100%;
      }
    `}
  `}
`

let buttonVariation = false

const ResultsButton = ({
  url,
  text,
  arrow = true,
  variation = undefined,
} : {
  url: string,
  text: string,
  arrow?: boolean,
  variation?: ButtonStyleVariationType | 'info'
}) => {
  if (!url || !text) return null

  let actualVariation = variation
  let actualArrow = arrow

  if (!actualVariation) {
    actualVariation = buttonVariation ? 'brand' : 'primary'
    buttonVariation = !buttonVariation
  }

  if (actualVariation === 'info') {
    actualArrow = false
    actualVariation = undefined // not a real button variation
  }

  const buttonLinkProps: {
    iconPosition?: 'left' | 'right' | 'none',
    animation?: string,
    variation?: ButtonStyleVariationType,
  } = {
    iconPosition: actualArrow ? 'right' : undefined,
    animation: actualArrow ? 'shiftRight' : undefined,
    variation: actualVariation,
  }

  const inlineLinkProps = {
    url,
    text,
    iconName: actualArrow ? 'arrow-right' : undefined,
  }

  return (
    <WideButtonLinkContainer info={variation === 'info'}>
      <ButtonLink {...buttonLinkProps}>
        <InlineLink {...inlineLinkProps} />
      </ButtonLink>
    </WideButtonLinkContainer>
  )
}

export default ResultsButton
