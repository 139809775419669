import { useContext } from 'react'
import {
  confirmUserAttribute,
  fetchMFAPreference,
  sendUserAttributeVerificationCode,
  updateMFAPreference,
} from 'aws-amplify/auth'
import Link from 'next/link'
import SessionContext from '../../../../contexts/session-context'
import CommonVerifyPhoneNumberForm from '../../common/VerifyPhoneNumberForm/verify-phone-number-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const generalError = (
  <div>
    We are unable to update your phone number. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>

)

const resendGeneralError = (
  <div>
    We are unable to send you a verification code. Please try again
    in 60 seconds.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const VerifyPhoneNumberForm = (
  {
    onCodeVerified,
    codeReRequestTime = undefined,
  }: {
    onCodeVerified: () => void,
    codeReRequestTime?: number,
  },
) => {
  const { refreshUser } = useContext(SessionContext)

  const submitVerification = async (smsCode: string) => {
    try {
      await confirmUserAttribute({
        userAttributeKey: 'phone_number',
        confirmationCode: smsCode,
      })
      const mfaPreference = await fetchMFAPreference()
      if (!mfaPreference.preferred) {
        await updateMFAPreference({ sms: 'PREFERRED' })
      }

      if (refreshUser) { await refreshUser() }

      onCodeVerified()
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'CodeMismatchException':
            return ['Please enter a valid verification code.']
          case 'EmptyConfirmUserAttributeCode':
            return ['Verification code is required.']
          case 'ExpiredCodeException':
            return ['Your code has expired. Please request a new verification code. It may take up to 60 seconds for you to receive your code.']
          case 'LimitExceededException':
            return ['You have submitted too many verification codes. Please wait 1 hour before requesting another code.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occurred when submitting a verification code. The method that caused this error is either confirmUserAttribute or updateMFAPreference',
              error,
              cognitoMethod: 'confirmUserAttribute or updateMFAPreference',
            })
            return [generalError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito occurred when submitting a verification code. The method that caused this error is either confirmUserAttribute or updateMFAPreference',
        error,
        cognitoMethod: 'confirmUserAttribute or updateMFAPreference',
      })
      return [generalError]
    }
    return []
  }

  const requestVerification = async () => {
    try {
      await sendUserAttributeVerificationCode({ userAttributeKey: 'phone_number' })
      return []
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'LimitExceededException':
            return ['You have requested too many verification codes. Please wait 1 hour before requesting another code.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occurred when requesting a new verification code. The method that caused this error is sendUserAttributeVerificationCode.',
              response: error,
              cognitoMethod: 'sendUserAttributeVerificationCode',
            })
            return [resendGeneralError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito occurred when requesting a new verification code. The method that caused this error is sendUserAttributeVerificationCode.',
        response: error,
        cognitoMethod: 'sendUserAttributeVerificationCode',
      })
      return [resendGeneralError]
    }
  }

  return (
    <CommonVerifyPhoneNumberForm
      submitVerificationCode={submitVerification}
      requestVerificationCode={requestVerification}
      codeReRequestTime={codeReRequestTime}
    />
  )
}

export default VerifyPhoneNumberForm
