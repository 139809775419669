import {
  ComparisonCard,
  DetailCard,
  GenericCard,
  QuoteCard,
  SpotlightCard,
  TitleCard,
  HeroCard,
  ButtonLink,
  ServiceCard,
} from '@sh24/ui-components'
import InlineLink from '../../InlineLink/inline-link'

export default {
  comparison: {
    imageSizes: null,
    Button: ({ text, link }) => (
      <InlineLink url={link} text={text} context="comparison-card" />
    ),
    SecondaryLink: null,
    Card: ComparisonCard,
  },
  detail: {
    imageSizes: {
      width: 620,
      height: 400,
    },
    Button: ({ text, link, icon }) => (
      <ButtonLink animation="shiftRight" variation="primary" iconOnly>
        <InlineLink url={link} ariaLabel={text} iconName={icon ?? 'arrow-right'} context="detail-card" />
      </ButtonLink>
    ),
    SecondaryLink: null,
    Card: DetailCard,
  },
  generic: {
    imageSizes: null,
    Button: null,
    SecondaryLink: null,
    Card: GenericCard,
  },
  quote: {
    imageSizes: null,
    Button: null,
    SecondaryLink: null,
    Card: QuoteCard,
  },
  spotlight: {
    imageSizes: {
      width: 140,
      height: 190,
    },
    Button: ({ text, link, icon }) => (
      <ButtonLink animation="shiftRight" variation="primary" iconOnly>
        <InlineLink url={link} ariaLabel={text} iconName={icon ?? 'arrow-right'} context="spotlight-card" />
      </ButtonLink>
    ),
    SecondaryLink: null,
    Card: SpotlightCard,
  },
  title: {
    imageSizes: {
      width: 654,
      height: 366,
      layout: 'fill',
    },
    Button: null,
    SecondaryLink: null,
    Card: TitleCard,
  },
  hero: {
    imageSizes: {
      width: 540,
      height: 640,
    },
    Button: ({ text, link }) => (
      <ButtonLink animation="shiftRight" variation="primary" iconPosition="right">
        <InlineLink url={link} text={text} iconName="arrow-right" context="hero-card" />
      </ButtonLink>
    ),
    SecondaryLink: null,
    Card: HeroCard,
  },
  service: {
    imageSizes: {
      width: 538,
      height: 192,
    },
    Button: ({
      text,
      link,
      variation,
      icon,
    }) => (
      <ButtonLink variation={variation} iconPosition="right">
        <InlineLink url={link} text={text} context="service-card" iconName={icon} />
      </ButtonLink>
    ),
    SecondaryLink: ({ text, link, withIcon }) => (
      <ButtonLink variation="text">
        <InlineLink url={link} text={text} context="service-card" iconName={withIcon && 'arrow-right'} iconPosition={withIcon && 'right'} />
      </ButtonLink>
    ),
    Card: ServiceCard,
  },
}
