import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import VerifyPhoneNumberForm from '../../../Forms/SignIn/VerifyPhoneNumberForm/verify-phone-number-form'

const VerifyPhoneNumberModule = () => {
  const { push } = useRouter()

  const onEmailConfirmed = async () => {
    const redirectUrl = await sessionStorage.getItem('redirectUrl')
    sessionStorage.removeItem('redirectUrl')
    await push(redirectUrl || '/account/home')
  }

  const onEmailUnconfirmed = async () => {
    await push('/account/sign-up/verify-email')
  }
  return (
    <Content
      width="standard"
      textAlign="left"
      content={
      [
        <VerifyPhoneNumberForm
          key="form"
          onEmailConfirmed={onEmailConfirmed}
          onEmailUnconfirmed={onEmailUnconfirmed}
        />,
      ]
    }
    />
  )
}

export default VerifyPhoneNumberModule
