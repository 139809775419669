import productToBasketItem from './product-to-basket-item'

const getProduct = ({
  products = [],
  identifier,
}) => products.find((product) => product.identifier === identifier)

export const inBasket = ({
  basket = [],
  identifier,
}) => basket.some((basketItem) => basketItem.identifier === identifier)

export const addToBasket = ({
  basket = [],
  products = [],
  identifier,
}) => {
  const product = getProduct({ products, identifier })

  if (product && !inBasket({ basket, identifier })) {
    const basketItem = productToBasketItem(product)

    return basketItem.mutuallyExclusive
      ? [...basket.filter((item) => !item.mutuallyExclusive), basketItem]
      : [...basket, basketItem]
  }

  return basket
}

export const removeFromBasket = ({
  basket = [],
  identifier,
}) => basket.filter((basketItem) => basketItem.identifier !== identifier)

export const getBasketTotals = ({ totals, translations, promoCta }) => {
  const {
    basketTotal,
    deliveryTotal,
    multiProductDiscount,
    promoDiscount,
  } = totals ?? {}

  return [
    basketTotal?.amount ? {
      text: translations[basketTotal.translationKey],
      price: basketTotal.formattedAmount,
    } : null,
    deliveryTotal ? {
      text: translations[deliveryTotal.translationKey],
      subText: deliveryTotal.label,
      price: deliveryTotal.amount === 0
        ? translations['orderJourney.totals.free']
        : deliveryTotal.formattedAmount,
    } : null,
    multiProductDiscount?.amount ? {
      text: translations[multiProductDiscount.translationKey],
      price: multiProductDiscount.formattedAmount,
    } : null,
    promoDiscount?.amount ? {
      text: translations[promoDiscount.translationKey],
      subText: promoDiscount.label,
      price: promoDiscount.formattedAmount,
      cta: promoCta,
    } : null,
  ]
}

export const updateProductSelectedOption = ({ products, optionIdentifier, identifier }) => (
  products.map((item) => {
    if (item.identifier !== identifier) return item
    const shouldUpdateOptions = !!item.options?.find((o) => o.identifier === optionIdentifier)

    return {
      ...item,
      options: shouldUpdateOptions ? item.options.map((option) => ({
        ...option,
        selected: option.identifier === optionIdentifier,
      })) : item.options,
    }
  })
)

export const refreshBasketItem = ({ basket, products, identifier }) => {
  const basketMinusItem = removeFromBasket({ basket, identifier })
  return addToBasket({ basket: basketMinusItem, products, identifier })
}

export const createBasketDescription = ({
  basket,
  orderTypeDescription,
}) => {
  const products = basket.map((item) => (item.selectedOption?.name || item.name || item.productName))
  return `${orderTypeDescription || 'Order'}: ${products.join(', ')}`
}
